import { BoxProps, theme } from '@dtx-company/shared-components/src'
import {
  COLORS,
  COLORS_DEPRECATED,
  LinkShape,
  LinkStyle,
  STYLE,
  Theme,
  ThemeCalcs
} from '../constants/customizeTheme'
import { DEFAULT_COLOR } from '@dtx-company/true-common/src/constants/page'
import { Theme as ThemeType } from '@dtx-company/flow-codegen/src/page/generated.types'
import { UnauthPageQueryTheme } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { defaultUserAttributes } from '../constants'
import { validateHexInput } from '@dtx-company/true-common/src/utils/strings'

const { colors, shadows } = theme
interface ColorCalcs {
  r: number
  g: number
  b: number
}

export function hexToRgb(hex: string): ColorCalcs {
  const bigint = parseInt(hex.replace('#', ''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

export function getContrastText({ r, g, b }: ColorCalcs): string {
  if ((r + g + b) / 3 < 200) {
    return '#FFF'
  }
  return '#000'
}

export function rgbToColorString({ r, g, b }: ColorCalcs): string {
  return `rgb(${r}, ${g}, ${b})`
}
export function calculateColor(type: string, factor: number, color: string): ColorCalcs {
  const { r: oldR, g: oldG, b: oldB } = hexToRgb(color || DEFAULT_COLOR)
  let r, g, b
  if (type === ThemeCalcs.SHADE) {
    r = oldR * (1 - factor)
    g = oldG * (1 - factor)
    b = oldB * (1 - factor)
  } else {
    r = oldR + (255 - oldR) * factor
    g = oldG + (255 - oldG) * factor
    b = oldB + (255 - oldB) * factor
  }

  return { r, g, b }
}

export function getCustomThemeValues(
  userTheme: ThemeType | UnauthPageQueryTheme | null | undefined
): {
  primaryColor: string
  contrastText: string
  style: string
  baseColor: string
  baseContrastText: string
} {
  const userColor = userTheme?.primaryColor
  const primaryColor = getColor(userColor)
  const { type, factor } = STYLE[userTheme?.style || defaultUserAttributes.theme.style]

  const { r, g, b } = calculateColor(type, factor, primaryColor)
  const styleVal = rgbToColorString({ r, g, b })

  let primaryColorOpacity = 0.4
  if (userTheme?.style === 'color') primaryColorOpacity = 0.75
  if (userTheme?.style === 'dark') primaryColorOpacity = 0.25

  return {
    primaryColor: rgbToColorString(
      calculateColor(ThemeCalcs.SHADE, primaryColorOpacity, primaryColor)
    ),
    baseColor: primaryColor,
    baseContrastText: getContrastText(calculateColor(ThemeCalcs.SHADE, 0, primaryColor)),
    style: styleVal,
    contrastText: getContrastText({ r, g, b })
  }
}

export function getBackgroundColor(color: string, style: string): string {
  const { type, factor } = STYLE[style]
  return rgbToColorString(calculateColor(type, factor, color))
}

export function getBackgroundColorAsRgb(color: string, style: string): ColorCalcs {
  const { type, factor } = STYLE[style]
  return calculateColor(type, factor, color)
}

export function getColor(color?: string | null, style?: string | null): string {
  if (!color) {
    return COLORS[DEFAULT_COLOR]
  }

  /**
   * If color is already hex return it. Introduced in sc-42683
   * see. {@link https://app.shortcut.com/flowcode/story/42683/hex-on-page-functionality} */
  if (validateHexInput(color)) return color

  if (style && color && (style === Theme.color || style === Theme.dark) && color === 'black') {
    const c = rgbToColorString(calculateColor(ThemeCalcs.TINT, 0.4, COLORS[color] ?? DEFAULT_COLOR))
    return c
  }
  if (style && color && style === Theme.color) {
    const c = rgbToColorString(
      calculateColor(ThemeCalcs.SHADE, 0.4, COLORS[color] ?? DEFAULT_COLOR)
    )
    return c
  }

  if (Object.keys(COLORS_DEPRECATED).includes(color)) {
    return COLORS_DEPRECATED[color]
  }
  return COLORS[color] ?? DEFAULT_COLOR
}

export function createColorsObj(colors: Record<string, string>[]): Record<string, string> {
  return colors.reduce((acc, curr) =>
    curr ? { ...acc, [Object.keys(curr)[0]]: Object.values(curr)[0] } : acc
  )
}

export function getBgOpacity(style: string | null | undefined): number {
  if (style === Theme.color || style === Theme.dark) {
    return 0.5
  }
  if (style === Theme.none) {
    return 1
  }
  return 0.7
}

export function getLinkSelectorStyles(style: LinkStyle): Omit<BoxProps, 'children' | 'onClick'> {
  const baseStyles = {
    minWidth: ['100px', '100px', '150px'],
    minHeight: ['48px', '48px', '70px'],
    width: ['100px', '100px', '150px'],
    height: ['48px', '48px', '70px'],
    borderRadius: '12px'
  }

  switch (style) {
    case LinkStyle.DEFAULT:
      return {
        ...baseStyles,
        border: `2px solid ${colors.secondary.border}`,
        boxShadow: shadows.float
      }
    case LinkStyle.OUTLINED:
      return {
        ...baseStyles,
        border: `2px solid ${colors.primary.grey}`,
        bg: colors.primary.white
      }
    case LinkStyle.OUTLINED_BW:
      return {
        ...baseStyles,
        border: `2px solid ${colors.primary.grey}`,
        bg: colors.primary.white
      }
    case LinkStyle.INSET:
      return {
        ...baseStyles,
        border: `2px solid ${colors.primary.grey}`,
        boxShadow: 'rgb(0 0 0 / 35%) 7px 7px 0px 0px'
      }
    case LinkStyle.COLORED:
      return {
        ...baseStyles,
        background: colors.primary.grey
      }
    case LinkStyle.GRADIENT:
      return {
        ...baseStyles,
        background: `linear-gradient(358.57deg, rgba(173, 173, 173, 0.8) 6.45%, rgba(196, 196, 196, 0.2) 98.84%)`
      }
  }
}

export function getLinkSelectorShape(style: LinkShape): Omit<BoxProps, 'children' | 'onClick'> {
  const baseStyles = {
    width: ['100px', '100px', '150px'],
    height: ['48px', '48px', '70px'],
    bg: [colors.masterWeb.grey4, colors.masterWeb.grey4, colors.primary.grey]
  }
  switch (style) {
    case LinkShape.DEFAULT:
      return {
        ...baseStyles,
        borderRadius: '12px'
      }
    case LinkShape.ROUND:
      return {
        ...baseStyles,
        borderRadius: '40px'
      }
    case LinkShape.SQUARE:
      return { ...baseStyles, borderRadius: '0px' }
  }
}

/**
 * We need to getColor(primaryColor) because there are pages still use named
 * colors, like 'blueLight'. Named colors need to be converted to hex, then to rgb.
 * New pages use a hex string for primaryColor so, if we migrate pages with named
 * primaryColor in the backend, `getColor` won't be necessary.
 */
export const getRGBFromPrimaryColor = (
  primaryColor?: string
): { r: number; g: number; b: number } => {
  return hexToRgb(getColor(primaryColor))
}

import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ColorInputHex } from '@dtx-company/shared-components/src/components/molecules/ColorInputHex'
import { EditTheme } from './types'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { ProFeatureWrapper } from '@app/code/src/components/ProFeatureWrapper'
import { ProUpgradeSourceIds } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface FontStylePickerProps {
  linkColor?: string | null
  handleChange: (values: EditTheme) => void
  shadowColor?: string | null
  isInsetTheme?: boolean
  borderColor?: string | null
  disabled?: boolean
}
const LinkColorOptions: FC<FontStylePickerProps> = ({
  handleChange,
  shadowColor,
  linkColor,
  borderColor,
  disabled = false,
  isInsetTheme
}) => {
  return (
    <Box flexDirection="column">
      <Box>
        <ColorInputHex
          label="Link Color"
          disabled={disabled}
          color={linkColor ?? ''}
          onColorChange={color => {
            events.Flowpage_PageProfile_Selected_Page_Level_Link_Color(color)
            handleChange({ linkColor: color })
          }}
          width={{ _: '50%', md: '144px' }}
        />
        <Spacer mr="8px" />
        {isInsetTheme ? (
          <ColorInputHex
            label="Shadow Color"
            disabled={disabled}
            color={shadowColor ?? ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Page_Level_Shadow_Color(color)
              handleChange({ shadowColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
          />
        ) : (
          <ColorInputHex
            label="Border Color"
            disabled={disabled}
            color={borderColor ?? ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Page_Level_Border_Color(color)
              handleChange({ borderColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
          />
        )}
      </Box>
    </Box>
  )
}

export const LinkColorPicker: FC<FontStylePickerProps> = props => {
  const { hasOperation } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const userCanViewLinkColorOptions = hasOperation('view:page_style_options')
  return (
    <>
      <Box opacity={props.disabled ? 0.35 : 1}>
        <Icon display={['none', 'none', 'flex']} src={icons.paintPalette} />
        <Spacer display={['none', 'none', 'flex']} mr="8px" />
        <Text
          color="primary.black"
          variant={['body/small', 'body/small', 'body/medium']}
          fontWeight={[600, 600, 'bold']}
        >
          Choose a link color
        </Text>
      </Box>
      <Spacer mb="16px" />
      {userCanViewLinkColorOptions ? (
        <LinkColorOptions {...props} disabled={hasViewOnlyAccess || props.disabled} />
      ) : (
        <ProFeatureWrapper
          tooltipId="flowpage-pro-fonts-tooltip"
          sourceId={ProUpgradeSourceIds.FLOWPAGE_STYLE_FONT}
          lockModalVariant={LockModalVariants.FP_PRO_PAGE_LEVEL_LINK_COLORS}
          cursor="pointer"
          alignItems="center"
          gateFeatureAndHideTooltip
          trackingCall={() => {
            events.Flowpage_PageProfile_Clicked_Page_Level_Link_Colors_Lock()
          }}
        >
          <Box flexDirection="column">
            <LinkColorOptions {...{ ...props, disabled: true }} />
            <Spacer mb="20px" />
          </Box>
        </ProFeatureWrapper>
      )}
    </>
  )
}

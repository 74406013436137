export enum SlotType {
  SETTINGS_PIXEL = 'SETTINGS_PIXEL',
  SETTINGS_REMOVE_FOOTER = 'SETTINGS_REMOVE_FOOTER',
  SETTINGS_TAB_NAVIGATION = 'SETTINGS_TAB_NAVIGATION',
  CUSTOM_FOOTER = 'CUSTOM_FOOTER',
  SETTINGS_GEOLOCATION = 'SETTINGS_GEOLOCATION',
  SETTINGS_FLOWCODE_PIXEL = 'SETTINGS_FLOWCODE_PIXEL'
}

export enum PixelType {
  iframe = 'iframe',
  image = 'image'
}

export interface PixelDataType {
  src: string //todo add url validation
  name: string
  isEnabled: boolean
  pixelType: PixelType
  snippet?: string
  isNotSaved?: boolean
}

export interface RemoveFooterDataType {
  isEnabled: boolean
}

interface GeoDataType {
  isEnabled: boolean
}

/**
 * What's the best way to type different json objects. Since the settings request will return different ones eventually?
 */
export interface SlotDataType {
  id: string
  data: PixelDataType | RemoveFooterDataType | GeoDataType
}

export interface GetFlowpageSettingsResponseType {
  slots: SlotDataType[]
}

import * as icons from '../../../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { EditTheme } from '../../../../../components/profile/Customize/types'
import { FC, useMemo } from 'react'
import {
  FONT_STYLESHEETS,
  FontFamilies,
  defaultUserTheme
} from '@dtx-company/true-common/src/constants/page'
import { FontStylePicker } from '../../../../../components/profile/Customize/FontStylePicker'
import { GoogleFontsPicker } from '../../../../../components/profile/Customize/GoogleFonts/GoogleFontsPicker'
import { GoogleFontsUpgradeTile } from '../../../../../components/profile/Customize/GoogleFonts/GoogleFontsUpgradeTile'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { ProUpgradeSourceIds } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.types'
import { Select } from '@dtx-company/shared-components/src/components/atoms/Select'
import { SelectOption } from '@dtx-company/shared-components/src/types/atoms/options.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { closeLinkEditDrawer } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { getLockedFieldFromTemplateConfig } from '../../../../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { stopPropagationEvents } from '../LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useDispatch } from 'react-redux'
import { useEditThemeWithAutosave } from '../../../../../components/profile/Customize/hooks'
import { useGatedFlowpageFontOptions } from '../../../../../components/profile/Customize/FontFamilyPicker.hooks'
import { useOpenProUpgradeModal } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.hooks'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export const FontMenu: FC<{ pageTheme: PageType['theme'] }> = ({ pageTheme }) => {
  const { availableFonts, gatedFonts, userIsViewer } = useGatedFlowpageFontOptions()
  const { hasOperation } = useAuthState()
  const dispatch = useDispatch()
  const { __typename, ...curTheme } = pageTheme || defaultUserTheme
  const editTheme = useEditThemeWithAutosave()
  const { isTemplateEditor } = usePageTemplateEditorState()

  const handleSelect = (option: string): void => {
    events.Flowpage_PageProfile_Selected_Font({
      font: option,
      deviceType: 'mobile'
    })
    editTheme({
      themeInput: {
        ...curTheme,
        fontFamily: option
      },
      themeId: curTheme.id
    })
  }
  const handleFontStyleChange = (theme: EditTheme): void => {
    editTheme({
      themeInput: {
        ...curTheme,
        ...theme
      },
      themeId: curTheme.id
    })
  }

  const availableFontOptions: SelectOption[] = useMemo(
    () => availableFonts.map(availFont => ({ value: availFont, label: availFont })),
    [availableFonts]
  )
  const gatedFontOptions: SelectOption[] = useMemo(
    () => gatedFonts.map(gatedFont => ({ value: gatedFont, label: gatedFont, icon: icons.lock })),
    [gatedFonts]
  )
  const { handleProtectedActionClick: openProModal } = useOpenProUpgradeModal({
    sourceId: ProUpgradeSourceIds.FLOWPAGE_CHANGE_FONT,
    openProModalCondition: true
  })
  const canUsePaidFonts = hasOperation('view:page_fonts')
  const showGoogleFontUpgradeTile = !canUsePaidFonts

  const disabled =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: pageTheme?.templateConfig,
      field: 'fontFamily'
    })
  return (
    <Box flexDirection="column" width="100%" p="16px 3px">
      <Text variant="body/small" opacity={disabled ? 0.35 : 1} fontWeight={600}>
        {canUsePaidFonts ? 'Choose from Google Fonts' : 'Choose a page font'}
      </Text>
      <Spacer mb="8px" />
      {canUsePaidFonts ? (
        <Box minWidth="100%" {...stopPropagationEvents} opacity={disabled ? 0.35 : 1} pr="3px">
          <GoogleFontsPicker
            currFont={curTheme.fontFamily ?? defaultUserTheme.fontFamily}
            handleSelect={handleSelect}
            disabled={disabled}
          />
        </Box>
      ) : (
        <Box {...stopPropagationEvents} opacity={disabled ? 0.35 : 1} width="100%">
          <Image
            height="44px"
            border="1px solid black"
            padding="4px"
            borderRadius="4px"
            src={
              FONT_STYLESHEETS[(curTheme.fontFamily || defaultUserTheme.fontFamily) as FontFamilies]
                ?.iconSrc
            }
          />
          <Spacer mr="16px" />

          <Select
            optionsMaxHeight={'200px'}
            disabled={userIsViewer || disabled}
            name="font-selector-mobile"
            value={{
              label: curTheme.fontFamily || defaultUserTheme.fontFamily,
              value: curTheme.fontFamily || defaultUserTheme.fontFamily
            }}
            options={availableFontOptions}
            disabledOptions={!hasOperation('view:page_fonts') ? gatedFontOptions : undefined}
            onChange={option => handleSelect(option.value)}
            onDisabledClick={() => {
              openProModal()
              dispatch(closeLinkEditDrawer({}))
            }}
          />
        </Box>
      )}
      <Spacer mb="24px" />
      {showGoogleFontUpgradeTile && <GoogleFontsUpgradeTile />}
      <FontStylePicker
        handleChange={handleFontStyleChange}
        fontColor={curTheme.fontColor}
        titleAndDescriptionColor={curTheme.titleColor}
        textSize={curTheme.textSize}
        disabled={disabled}
      />
    </Box>
  )
}

import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { PrimaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { ProUpgradeSourceIds } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { TextLink } from '@dtx-company/inter-app/src/utils/links'
import { fireHeapEvent } from '@dtx-company/inter-app/src/event-tracking/helpers'
import { googleFontsTileMobile, rocketFilledBlue } from '@app/code/src/assets'
import { useOpenProUpgradeModal } from '@app/code/src/components/ProUpradeModal/ProUpgradeModal.hooks'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export const GoogleFontsUpgradeTile: FC = () => {
  const { handleProtectedActionClick } = useOpenProUpgradeModal({
    openProModalCondition: true,
    action: () => window.location.reload(),
    sourceId: ProUpgradeSourceIds.FLOWPAGE_CHANGE_FONT,
    lockModalVariant: LockModalVariants.FP_PRO_FONTS,
    trackingCall: () => {
      events.userClickedGatedFlowpageFontOptions()
    }
  })
  const FONT_UPGRADE_BLOG_URL =
    'https://www.flowcode.com/blog/introducing-flowpage-google-fonts-integration'
  return (
    <Box
      borderRadius="8px"
      width="100%"
      backgroundColor="#E6ECFC"
      overflow="auto"
      data-testid="google-fonts-upgrade-tile"
    >
      <Box
        width="100%"
        padding={{ _: '16px', md: '24px', lg: '24px' }}
        flexDirection="column"
        backgroundColor=" #E6ECFC"
        background={`url(${googleFontsTileMobile}) no-repeat right`}
        backgroundSize={{ _: 'cover', md: 'contain', lg: 'contain' }}
      >
        <Box>
          <Icon src={rocketFilledBlue} width="16px" height="16px" />
          <Spacer mr="8px" />
          <Text fontSize="16px" fontWeight={700} color="primary.flowBlue">
            Font Upgrade
          </Text>
        </Box>
        <Spacer mb="10px" />
        <Text variant="body/medium" maxWidth="60%">
          Upgrade to customize your brand style with 1,000+ Google Fonts.
        </Text>
        <Spacer mb="18px" />
        <Box alignItems="center">
          <PrimaryButton
            padding="12px"
            maxWidth="60%"
            onClick={() => {
              handleProtectedActionClick()
              fireHeapEvent('Flowpage_PageProfile_Clicked_UpgradeButton', {
                lockSelected: 'fpFonts'
              })
            }}
          >
            Upgrade Now
          </PrimaryButton>
          <Spacer mr="16px" />
          <TextLink
            textDecoration="underline"
            color="primary.flowBlue"
            fontSize="14px"
            isExternal
            onClick={() =>
              fireHeapEvent('Flowpage_PageProfile_Clicked_LearnMore', { lockSelected: 'fpFonts' })
            }
            href={FONT_UPGRADE_BLOG_URL}
          >
            Learn More
          </TextLink>
        </Box>
      </Box>
    </Box>
  )
}

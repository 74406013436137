import { FC } from 'react'
import { LinkStyle } from './LinkStyle'
import { LinkThemeState } from './types'
import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { ProFeatureWrapper } from '@app/code/src/components/ProFeatureWrapper'
import { TooltipContent } from '@dtx-company/design-system/src'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import useTranslation from 'next-translate/useTranslation'

export const LinkLevelStylingTooltipContent: FC = () => {
  const { t } = useTranslation('page')
  return (
    <TooltipContent
      title={t('Widgets.linkStyleGated.tooltip.title')}
      content={t('Widgets.linkStyleGated.tooltip.content')}
    />
  )
}

export const LinkStyleGated = (props: LinkThemeState): JSX.Element => {
  const { hasOperation } = useAuthState()
  const canViewLinkStyleOptions = hasOperation('view:link_style_options')
  const isMobile = useIsMobile()

  if (canViewLinkStyleOptions) return <LinkStyle {...props} />
  return (
    <ProFeatureWrapper
      sourceId=""
      gateFeatureAndHideTooltip={isMobile}
      showTooltip={!isMobile}
      lockModalVariant={LockModalVariants.FP_LINK_LEVEL_STYLING}
      tooltipId="link-level-styling-tooltip"
      content={<LinkLevelStylingTooltipContent />}
      trackingCall={events.Flowpage_PageProfile_Clicked_Link_Level_Customization_Options}
      alignItems="center"
      isProPlus={true}
    >
      <LinkStyle {...props} disabled={true} />
    </ProFeatureWrapper>
  )
}

import { ActionDataWidgets, FormFields } from './types'
import { DefaultManager, ManagerProps } from './components/Manager/Manager'
import { EmbedOther } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedOther'
import { EmbedSpotify } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedSpotify'
import { EmbedVideo } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedVideo'
import { FC, useEffect, useMemo, useState } from 'react'
import { FlowpageProps } from './types/FlowpageProps'
import { FormProps, StyledForm } from './components/Layout'
import { FormTitle } from './components/Title'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkEmbeddedCard } from '../util/EmbeddedMediaPlayers'
import { LinkInput } from './components/Inputs'
import { SettingsAndSaveLink } from './components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { TITLE_MAX } from './validation'
import { addProtocol } from '../profile/LinkForm/utils'
import { defaultWidgetLink } from '../../constants'
import { getValidatedActionData, getValidatedActionDataOrThrow } from './typeUtils'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { logoUrlForType } from '../../utils/main'
import { useForm } from 'react-hook-form-deprecated'
import { useSetValueWithPreview, validateLink } from './utils'
import { useSubmitWidget } from './submitUtils'
import { v4 as uuid } from 'uuid'
import linkTypes, {
  LinkMetaType,
  genericLinkMeta
} from '@dtx-company/inter-app/src/constants/linkTypes'

const { LINK, TITLE } = FormFields
interface LinkFormFields {
  link: string
  title: string
}

const embeddableTypes: Record<string, ActionDataWidgets> = {
  youtubevideo: 'embedYoutube',
  spotifysong: 'embedSpotify',
  spotifyartist: 'embedSpotify',
  spotifyalbum: 'embedSpotify'
}

const watermarks: Record<string, string> = {
  embedYoutube: 'youtube.com/watch?v=...',
  embedSpotify: 'open.spotify.com/track/...'
}

export const getEmbedType = (meta: LinkMetaType): ActionDataWidgets | undefined =>
  indexInterfaceWithVar(embeddableTypes, meta.provider + meta.type)

export const Form: FC<FormProps> = ({
  widgetObj: linkObj,
  order,
  curr,
  handleClose
}: FormProps) => {
  const actionData = getValidatedActionData<'embedYoutube'>(curr?.actionData, 'link')
  const linkProvider = actionData?.provider || linkObj.provider
  const initialMeta = linkTypes.find(item => item.provider === linkProvider)

  const [meta, setMeta] = useState<LinkMetaType>(initialMeta || genericLinkMeta)
  const id = curr ? curr.id : uuid()
  const {
    register,
    setValue: setFormValue,
    setError,
    handleSubmit,
    errors,
    watch,
    trigger
  } = useForm({
    defaultValues: {
      link: actionData?.link?.replace(/http(s)?:\/\//, '') || '',
      title: curr?.title || ''
    },
    mode: 'all'
  })
  const submitLink = useSubmitWidget()
  const onSubmit = async ({ link, title }: LinkFormFields): Promise<void> => {
    const actionData = {
      link: addProtocol(link),
      provider: meta.provider,
      type: meta.type
    }
    await submitLink({
      curr,
      actionData,
      widgetType: getEmbedType(meta) || 'embeddableMedia',
      handleClose,
      fields: {
        id,
        order,
        title
      }
    })
  }
  const watchAll = watch()
  useEffect(() => {
    register(LINK, {
      validate: value => {
        const isValid = validateLink(value, setMeta)
        if (!meta?.embeddable && watchAll?.link) {
          return 'This link type is not embeddable'
        }
        return isValid
      },
      required: true
    })
    register(TITLE)
  }, [meta?.embeddable, register, watchAll?.link])
  const disabled = watchAll.link.length === 0
  const embeddedMediaProvider = linkObj.type?.replace('embed', '')
  const embeddableMediaType = embeddedMediaProvider === 'Spotify' ? 'music' : 'video'
  const embeddableMediaName =
    !linkObj.type || linkObj.type === 'embeddableMedia'
      ? 'embedded media'
      : embeddedMediaProvider + ' ' + embeddableMediaType
  const edit = Boolean(curr)
  const previewLink = useMemo(
    () => ({
      ...defaultWidgetLink,
      provider: meta.provider,
      type: meta.type,
      actionData: {
        link: addProtocol(watchAll.link)
      }
    }),
    [meta.provider, meta.type, watchAll.link]
  )
  const { setValue } = useSetValueWithPreview(previewLink, setFormValue)
  const embeddedMediaTooltipText =
    'You can embed media using URL links from the following platforms: Youtube, Facebook, Twitch, Soundcloud, Vimeo, Wistia, DailyMotion, Vidyard, Kaltura'
  let headerIcon
  if (!headerIcon) {
    switch (linkObj?.type) {
      case 'embedSpotify':
        headerIcon = <EmbedSpotify width="40px" height="40px" />
        break
      case 'embedYoutube':
        headerIcon = <EmbedVideo />
        break
      case 'embeddableMedia':
        headerIcon =
          curr && linkObj?.type === 'embeddableMedia' ? (
            <Icon
              src={logoUrlForType(curr?.actionData?.provider)}
              alt={`${curr?.actionData?.provider ?? 'embedded media'} icon`}
              height="40px"
            />
          ) : (
            <EmbedOther width="40px" height="40px" />
          )
        break
      default:
        headerIcon = <EmbedOther width="40px" height="40px" />
        break
    }
  }
  return (
    <>
      <StyledForm
        title={
          <FormTitle
            icon={headerIcon}
            title={`${edit ? 'Edit this' : `Add `} ${embeddableMediaName}`}
            tooltip={linkObj?.type === 'embeddableMedia' ? embeddedMediaTooltipText : ''}
          />
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="text"
          label="Title (optional)"
          labelProps={{ marginBottom: '8px' }}
          placeholder="Title"
          onBlur={() => trigger(LINK)}
          onChange={e => {
            setValue(TITLE, e.target.value, { shouldValidate: true })
          }}
          value={watchAll.title}
          inputProps={{ maxLength: TITLE_MAX }}
        />
        <LinkInput
          value={watchAll.link}
          errors={errors}
          onBlur={() => trigger(LINK)}
          placeholder={watermarks[linkObj.type || '']}
          onChange={e => {
            setValue(LINK, e.target.value, { shouldValidate: true })
          }}
        />
        <Spacer mt="80px" />
        <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  editLinkMode,
  isLockedTemplateLink
}: FlowpageProps) => {
  const actionData = getValidatedActionDataOrThrow<'embedYoutube'>(link.actionData, 'link')

  // only need contentId for spotify links, so a empty string fallback should be fine for most cases
  return (
    <LinkEmbeddedCard
      id={link.id}
      isLockedTemplateLink={isLockedTemplateLink}
      link={actionData.link}
      provider={actionData.provider}
      editLinkMode={editLinkMode}
    />
  )
}

export const Manager: FC<ManagerProps> = ({ link, editWidget, handle }: ManagerProps) => {
  const actionData = getValidatedActionDataOrThrow<'embedYoutube'>(link.actionData, 'link')

  return (
    <DefaultManager
      altTitle={'Embeddable Media'}
      handle={handle}
      editWidget={editWidget}
      link={link}
      iconUrl={logoUrlForType(actionData.provider)}
    />
  )
}

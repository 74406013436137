import { Friends } from '../discover/types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'
import { PromotedItemProps } from '../promotedItem/types'
import { SocialLinkFields } from '../Destination/SocialLinkFields.types'
import { StripeProduct } from '../ProductForSale/types'
import { UnauthPageQueryLink } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { WidgetType } from './widgetType'
export type LinkType = UnauthPageQueryLink
export enum FormFields {
  LINK = 'link',
  TITLE = 'title',
  DESCRIPTION = 'description',
  IMAGE = 'image',
  DISPLAY_TYPE = 'displayType',
  BUTTON_COLOR = 'buttonColor'
}

export type ActionDataWidgets = keyof ActionDataTypes
export interface ActionDataTypes {
  contactInfo: {
    firstName?: string
    lastName?: string
    call?: string
    company?: string
    website?: string
    title?: string
    card?: boolean
    email?: string
    text?: string
    location?: string /** formatted address used for form*/
    street1?: string
    street2?: string
    city?: string
    state?: string
    zip_code?: string
    country?: string
    iconSize?: number
    iconFill?: 'outline' | 'fill'
  }
  embedYoutube: {
    link: string
    provider: string
    type: string
  }
  embedSpotify: {
    link: string
    provider: string
    type: string
  }
  embeddableMedia: {
    link: string
    provider: string
    type: string
  }
  destination: {
    link: string
  }
  pdf: {
    link: string
  }
  productForSale: {
    products: StripeProduct[]
  }
  storeHours: {
    empty?: boolean
    hours: {
      [key in 'mon' | 'tues' | 'wed' | 'thurs' | 'fri' | 'sat' | 'sun']: {
        open: string
        close: string
        active: boolean
      }
    }
    isSameHours: boolean
    timeZone: string
  }
  sendMessage: {
    type: 'SMS' | 'Email' | 'Whatsapp' | 'Call'
    link: string
    name: string
    image?: string
    sendTo: string
    countryCode?: string
    subject?: string
    body?: string
  }
  polling: {
    question: string
    closed?: boolean
    displayResults: boolean
    options: {
      opt1: string
      opt2: string
      opt3?: string
      opt4?: string
      opt5?: string
      opt6?: string
    }
    results: {
      opt1: number
      opt2: number
      opt3?: number
      opt4?: number
      opt5?: number
      opt6?: number
    }
  }
  ageGate: {
    gateType: '21' | '18' | 'sensitive'
    errorMsg?: string
  }
  fbPixel: {
    pixelId: string
    utmPassthrough: boolean
  }
  flowPixel: {
    pixelPassthrough: boolean
  }
  pageMeta: {
    title: string
    description: string
  }
  smartLinks: {
    enabled: boolean
  }
  promotedItem: {
    items: PromotedItemProps[]
  }
  discover: {
    empty?: boolean
    friends: Friends
  }
  appStoreLink: {
    appStoreUrl?: string
    playStoreUrl?: string
  }
  socialLink: {
    instagram?: SocialLinkFields
    facebook?: SocialLinkFields
    twitter?: SocialLinkFields
    youtube?: SocialLinkFields
    snapchat?: SocialLinkFields
    tiktok?: SocialLinkFields
    linkedin?: SocialLinkFields
    spotify?: SocialLinkFields
    twitch?: SocialLinkFields
    patreon?: SocialLinkFields
    onlyfans?: SocialLinkFields
    venmo?: SocialLinkFields
    paradedeck?: SocialLinkFields
    cashappp?: SocialLinkFields
    pinterest?: SocialLinkFields
    iconSize: string
    iconStyle: string
  }
  textBoxSection: {
    title: string
    size?: number /** @deprecated use textType instead */
    textType?: string
    richText?: string
    richTextAlignment?: 'left' | 'center' | 'right'
  }
  bigImage: {
    maskType: MaskType
  }
  nft: {
    image: string
    link: string
  }
  contactCollection: {
    fields: ('dname' | 'phone' | 'address' | 'email' | 'custom' | 'custom2' | 'age' | 'gender')[]
    required?: ('dname' | 'phone' | 'address' | 'email' | 'custom' | 'custom2' | 'age' | 'gender')[]
    customFieldLabel?: string
    customFieldLabel2?: string
    link: string
    customTerms?: { enabled: boolean; disclaimer?: string }
    widgetDisplayType?: MaskType
    zapier?: { enabled: boolean; url?: string }
    klaviyo?: { enabled: boolean; url?: string }
    hubspot?: { enabled: boolean; apiKey?: string | null }
    salesforce: { enabled: boolean; apiKey?: string | null }
    googleSheets?: { enabled: boolean; url?: string }
    mailChimp?: { enabled: boolean; url?: string }
    successMessage?: { enabled: boolean; header?: string; body?: string }
    filledOutFormRequirement?: boolean
  }
}

export interface AddWidgetType {
  provider: LinkProvider.WIDGET | LinkProvider
  type: WidgetType
  id?: string
  displayText?: string
  title?: string
  icon?: string
  description?: string
}

// Todo: Remove once these widgets/links are stored as slots and not as links
export enum SlotWidgetTypes {
  'fbPixel' = 'fbPixel',
  'flowPixel' = 'flowPixel',
  'pageMeta' = 'pageMeta'
}
